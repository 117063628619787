// extracted by mini-css-extract-plugin
export var asciiLoader = "Terminal-module--ascii-loader--51f74";
export var content = "Terminal-module--content--42193";
export var controls = "Terminal-module--controls--b29a8";
export var form = "Terminal-module--form--73536";
export var form__footer = "Terminal-module--form__footer--cf14e";
export var form__header = "Terminal-module--form__header--bd56e";
export var form__input = "Terminal-module--form__input--e9920";
export var form__item = "Terminal-module--form__item--ceb75";
export var form__item__content = "Terminal-module--form__item__content--b51a0";
export var invalid = "Terminal-module--invalid--c8186";
export var loader = "Terminal-module--loader--fc337";
export var logo = "Terminal-module--logo--bb56a";
export var open = "Terminal-module--open--f94fd";
export var portfolio = "Terminal-module--portfolio--40be5";
export var portfolio__descr = "Terminal-module--portfolio__descr--2c70b";
export var portfolio__header = "Terminal-module--portfolio__header--76b7c";
export var portfolio__item = "Terminal-module--portfolio__item--75a50";
export var portfolio__tables = "Terminal-module--portfolio__tables--fdca5";
export var render = "Terminal-module--render--8b257";
export var space = "Terminal-module--space--f8279";
export var terminal = "Terminal-module--terminal--db104";
export var wrap = "Terminal-module--wrap--ba6dc";