import React from 'react'

const Landing = () => {
  return (
    <iframe
      src="https://everstake-capital.super.site/"
      width="100%"
      height="100vh"
      frameBorder="0"
      style={{ border: 0, height: '100vh' }}
      allowFullScreen
      title="Everstake Capital Site"
    />
  )
}

export default Landing
